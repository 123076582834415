<template>
    <div class="the-login w-100">
        <div class="the-login__container rounded-lg d-flex flex-column m-auto">
            <img
                class="the-login__logo"
                src="@/assets/login-logo-black.png"
                alt="Logo"
            />

            <app-form
                form-class="the-login__form"
                :on-submit="onSubmit"
                :needs-confirmation="false"
                :show-title-divider="false"
            >
                <template v-slot:fields>
                    <app-input
                        id="the-login__email"
                        label="Phone Number"
                        type="tel"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        :is-valid="isValid"
                        :required="true"
                        :invalid-feedback="$t('login.passwordWrong')"
                        @update-value="(e) => (phoneNumber = e)"
                        @country-changed="updateCountryPhoneNumber"
                    ></app-input>

                    <app-input
                        id="the-login__password"
                        :label="$t('password')"
                        type="password"
                        name="password"
                        :placeholder="$t('password')"
                        :is-valid="isValid"
                        :required="true"
                        :invalid-feedback="$t('login.passwordWrong')"
                        @update-value="(e) => (password = e)"
                    ></app-input>
                </template>

                <template v-slot:buttons>
                    <b-button
                        type="submit"
                        variant="primary"
                        class="w-100 the-login__button"
                        >{{ $t('forms.buttons.login') }}</b-button
                    >
                </template>
            </app-form>

            <div class="the-login__forgot">
                <a
                    class="the-login__help the-login__forgot-link mt-2"
                    href="/ForgotPassword"
                    target="_blank"
                    >{{ $t('login.forgotPassword') }}</a
                >

                <i18next
                    path="login.help"
                    tag="div"
                    class="the-login__help"
                    :options="{ email: 'admin@myxplora.com' }"
                >
                    <a href="mailto:admin@myxplora.com" target="_blank">{{
                        $t('login.helpEmail')
                    }}</a>
                </i18next>
            </div>
        </div>
    </div>
</template>

<script>
    import md5 from 'md5';
    import { login } from '@/api/graphql/requests';
    import getNumberWithoutCode from '@/util/get-phone-number-without-code';
    import AppInput from '../ui/AppInput.vue';
    import AppForm from '../ui/AppForm.vue';

    export default {
        components: { AppInput, AppForm },
        name: 'TheLogin',
        data() {
            return {
                isValid: null,
                countryPhoneNumber: '',
                phoneNumber: '',
                password: '',
            };
        },
        methods: {
            async onSubmit() {
                const password = md5(this.password);
                try {
                    await login({
                        countryPhoneNumber: this.countryPhoneNumber,
                        phoneNumber: getNumberWithoutCode(
                            this.phoneNumber,
                            this.countryPhoneNumber,
                        ),
                        password,
                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        userLang: 'en-GB',
                        client: 'WEB',
                    });

                    this.$router.push('/');
                } catch (e) {
                    this.isValid = false;
                }
            },
            updateCountryPhoneNumber(event) {
                this.countryPhoneNumber = `+${event.dialCode}`;
            },
        },
    };
</script>
