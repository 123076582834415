<template>
    <div class="login-container">
        <the-login></the-login>
    </div>
</template>

<script>
    import TheLogin from '@/components/sections/TheLogin.vue';

    export default {
        name: 'Home',
        components: {
            TheLogin,
        },
    };
</script>
